import React, { useState } from "react"

// Libraries
import styled, { keyframes } from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables/"
import { isEmailVaild } from "utils/functions/"

// Components
import Container from "components/container/"
import Form from "components/forms/"
import Dropdown from "components/form-dropdowns/"
import { Button } from "components/button"

// Images
import CandidatesImage from "assets/images/careers/candidates-image.svg"
import CandidatesImageError from "assets/images/careers/candidates-image-error.svg"

// Icons
import IconCaretRoundWhite from "assets/icons/icon-arrow-right.inline.svg"
import breakpoints from "utils/breakpoints/"

const StyledForm = styled(Form)`
  margin-top: 40px;
  margin-bottom: 48px;

  ${breakpoints.medium`
    margin-bottom: 0;
  `}

  .form__group {
    position: relative;
  }

  input,
  textarea {
    &.error {
      border-color: ${colors.watermelon};

      & + small {
        display: block;
      }
    }
  }

  input,
  .dropdown__title {
    border-bottom: ${colors.midnight} solid 1px;
  }

  textarea {
    height: 41px;
    transition: height 0.6s ease-in-out;

    &:focus,
    &:valid {
      height: 240px;

      ${breakpoint.small`
        height: 95px;
      `}
    }
  }

  .message {
    margin-top: 8px;
    font-size: 16px;

    &--error {
      color: ${colors.watermelon};
    }
  }
`

const StyledFormSection = styled.section`
  background-color: ${props =>
    props.noJobs ? colors.white : colors.cream};
  position: relative;
  padding: 80px 0;

  ${breakpoint.medium`
    padding: 160px 0 125px 0;
  `}
`

const dotCollisionBefore = keyframes`
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-15px);
  }
`

const dotCollisionAfter = keyframes`
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(15px);
  }
`

const StyledSubmitButton = styled(Button)`
  min-width: 180px;
  min-height: 58px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  background-color: ${props =>
    props.loading ? colors.midnight : colors.indiblue};
  color: ${colors.white};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${colors.midnight};
  }

  .dots {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${colors.white};
    color: ${colors.white};
  }

  .dots::before,
  .dots::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dots::before {
    left: -10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${colors.white};
    color: ${colors.white};
    animation: ${dotCollisionBefore} 1s infinite ease-in;
  }

  .dots::after {
    left: 10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${colors.white};
    color: ${colors.white};
    animation: ${dotCollisionAfter} 1s infinite ease-in;
    animation-delay: 0.5s;
  }
`

const FormConfirmation = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  background-color: ${colors.cream};
  opacity: ${props => (props.visible ? "1" : "0")};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  z-index: 1001;
  transition: all 0.6s;

  &.confirmation--success {
    h2,
    .subtitle {
      color: ${colors.indiblue};
    }
  }

  &.confirmation--error {
    h2,
    .subtitle {
      color: ${colors.watermelon};
    }
  }

  h2,
  .subtitle {
    font-family: ${fonts.primary};
    font-weight: 500;
  }

  h2 {
    max-width: 400px;
  }

  .subtitle {
    margin-top: 16px;
    margin-bottom: 40px;
  }

  .close__button {
    padding: 0px;

    svg {
      margin: 0px 8px 0px 0px;
    }
  }
`

const CandidatesForm = props => {
  const { noJobs } = props

  // State
  const [formFields, setFormFields] = useState({
    email: "",
    jobtitle: "UI/UX Designer",
  })
  const [formValidation, setFormValidation] = useState({
    email: true,
    jobtitle: true,
  })
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  const validateForm = () => {
    const _formValidation = formValidation

    for (const [key, value] of Object.entries(formFields)) {
      _formValidation[key] = value !== ""
      if (formFields["email"]) {
        _formValidation["email"] = isEmailVaild(formFields["email"])
      }
    }

    setFormValidation({
      ...formValidation,
      ..._formValidation,
    })
  }

  const handleInputChange = event => {
    const {
      target: { name, value },
    } = event

    setFormFields({
      ...formFields,
      [name]: value,
    })
  }

  const handleDropdownChange = event => {
    const {
      target: { name, textContent: value },
    } = event

    setFormFields({
      ...formFields,
      [name]: value,
    })
  }

  const handleSuccess = () => {
    if (!showSuccess) {
      setShowSuccess(true)
    }
  }

  const handleError = () => {
    if (!showError) {
      setShowError(true)
    }
  }

  const formIsValid = () => {
    return Object.values(formValidation).indexOf(false) === -1
  }

  const handleSubmission = async event => {
    event.preventDefault()

    validateForm()

    const isValid = formIsValid()

    if (isValid) {
      setLoading(true)

      const data = {
        fields: [
          {
            name: "email",
            value: formFields["email"],
          },
          {
            name: "jobtitle",
            value: formFields["jobtitle"],
          },
        ],
        context: {
          pageUri: "https://indicius.com/careers",
          pageName: "Careers",
        },
      }

      // Submits form to HubSpot
      await fetch(
        "https://api.hsforms.com/submissions/v3/integration/submit/2094487/0adb9b9a-7bd5-4f26-a298-09913bd3590c",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Content-Length": data.length,
          },
          body: JSON.stringify(data),
        }
      )
        .then(response => {
          if (response.status === 200) {
            handleSuccess()
          } else {
            handleError()
          }
        })
        .catch(() => {
          handleError()
        })

      setLoading(false)
    }
  }

  return (
    <StyledFormSection noJobs={noJobs}>
      <Container>
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            <h2 className="mb-3 font-weight--500">
              {noJobs ? (
                <Trans>
                  Sorry! Looks like there aren’t any open positions right now.
                </Trans>
              ) : (
                <Trans>Didn’t find what you were looking for?</Trans>
              )}
            </h2>
            {noJobs ? (
              <p>
                <Trans>
                  Leave us your info and we’ll get in touch as soon as one opens
                  up that’s right for you.
                </Trans>
              </p>
            ) : (
              <p>
                <Trans>
                  Tell us more about you. We’ll get in touch as soon as a
                  position opens up that’s right for you.
                </Trans>
              </p>
            )}
            <StyledForm onSubmit={handleSubmission} noValidate>
              <div className="form__group">
                <label htmlFor="email" className="form__label">
                  <Trans>what’s your email?</Trans>
                </label>
                <input
                  className={`${!formValidation["email"] ? "error" : ""}`}
                  type="email"
                  name="email"
                  placeholder="dany_dracarys@targaryen.com"
                  onChange={handleInputChange}
                />
                {!formValidation["email"] && (
                  <small className="message message--error">
                    <Trans>Please enter a valid email.</Trans>
                  </small>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="jobtitle" className="form__label">
                  <Trans>What’s your area?</Trans>
                </label>

                <Dropdown
                  name="jobtitle"
                  defaultOption="UI/UX Designer"
                  options={[
                    "UI/UX Designer",
                    "Front-End Developer",
                    "Project Manager",
                    "Human Resources (HR)",
                    "Sales",
                  ]}
                  callbackFunction={handleDropdownChange}
                />
              </div>

              <div className="form__group align--right">
                <StyledSubmitButton
                  loading={loading}
                  disabled={Object.values(formFields).includes("")}
                >
                  {loading ? <div className="dots" /> : <Trans>Submit</Trans>}
                </StyledSubmitButton>
              </div>
            </StyledForm>
          </div>
          <div className="col-12 col-md-6 text-center">
            <img src={CandidatesImage} alt="" />
          </div>
        </div>
      </Container>

      <FormConfirmation className="confirmation--success" visible={showSuccess}>
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-6">
            <h2>
              <Trans>Thanks for getting in touch!</Trans>
            </h2>
            <p className="subtitle">
              <Trans>We're excited to get to know you.</Trans>
            </p>
            <p>
              <Trans>{`You'll hear from us real soon! :)`}</Trans>
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src={CandidatesImage} alt="" />
          </div>
        </div>
      </FormConfirmation>

      <FormConfirmation className="confirmation--error" visible={showError}>
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-6">
            <h2>
              <Trans>Crickey! Something's not working.</Trans>
            </h2>
            <p className="subtitle">
              <Trans>It seems like your email wasn't sent.</Trans>
            </p>
            <div className="d-flex">
              <Button
                className="close__button d-flex"
                onClick={() => setShowError(false)}
              >
                <IconCaretRoundWhite className="icon mr-1" />
              </Button>
              <p className="d-flex align-items-center">
                <Trans>Go back and try again</Trans>
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <img src={CandidatesImageError} alt="" />
          </div>
        </div>
      </FormConfirmation>
    </StyledFormSection>
  )
}

export default CandidatesForm

import React from "react"

// Utils
import { colors } from "utils/variables/"

// Layout
import Layout from "layouts/layout-primary"

// Components
import Seo from "components/seo"

// Sections
import Hero from "sections/careers/hero"
import JobOpenings from "sections/careers/job-openings"
import CandidatesForm from "sections/careers/candidates-form"

// Libraries
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"

const Careers = props => {
  const {
    data: {
      jobs: { nodes: jobs },
    },
  } = props

  const { t } = useTranslation()
  return (
    <div>
      <Layout menuColor={colors.midnight}>
        <Seo title={t("Work at Indicius")} />
        <Hero />
        {jobs.length !== 0 && <JobOpenings data={jobs} />}

        <CandidatesForm noJobs={jobs.length === 0} />
      </Layout>
    </div>
  )
}

export const query = graphql`
  query($language: String!) {
    jobs: allContentfulJobPost(
      filter: {
        node_locale: { eq: $language }
        title: { ne: "Dummy Post (DO NOT DELETE)" }
      }
      sort: { order: DESC, fields: creationDate }
    ) {
      nodes {
        title
        modality
        creationDate(fromNow: true)
        node_locale
        slug
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Careers

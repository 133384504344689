import React from "react"

//Libraries
import { useTranslation } from "react-i18next"

// Components
import PageHero from "components/page-hero/"

const Hero = () => {
  const { t } = useTranslation()
  return (
    <PageHero
      title={t("Want to work on a versatile team of passionate creatives?")}
      highlight={t("Join us.")}
    />
  )
}

export default Hero

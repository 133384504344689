import React from "react"

// Libraries
import styled from "styled-components"
import { Link } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Icons
import IconCaretRoundWhite from "assets/icons/icon-arrow-right.inline.svg"

const StyledJobPosition = styled.div`
  position: relative;
  padding: 17px 0 17px 0;
  border-top: 1px ${colors.indiblue} solid;
  border-bottom: 1px ${colors.indiblue} solid;
  margin-bottom: -1px;

  &:hover {
    .icon {
      ${breakpoint.medium`
        transform: translateX(16px);
      `}

      * {
        fill: ${colors.midnight};
      }
    }
  }

  ${breakpoint.medium`
    padding: 34px 0 34px 0;
  `}

  .icon {
    position: absolute;
    top: 16px;
    right: 0;
    padding: 0;
    min-height: 24px;
    min-width: 24px;
    transition: all 0.3s ease;

    ${breakpoint.medium`
      position: relative;
      top: 0;
      right: 0;
    `}

    * {
      transition: all 0.3s ease;
    }
  }
`

const JobPosition = props => {
  const { title, duration, time, slug } = props

  return (
    <Link to={`/careers/${slug}`}>
      <StyledJobPosition>
        <div className="row">
          <div className="col-12 col-md-4">
            <p className="paragraph--small font-weight--500">{title}</p>
          </div>
          <div className="col-12 col-md-4">
            <p className="paragraph--small color--stone font-weight--500">
              {duration[0]}
            </p>
          </div>
          <div className="col-12 col-md-2 mt-3 mt-md-0">
            <p className="paragraph--small color--stone">{time}</p>
          </div>
          <div className="col-12 col-md-2 d-md-block text-md-center">
            <IconCaretRoundWhite className="icon" />
          </div>
        </div>
      </StyledJobPosition>
    </Link>
  )
}

export default JobPosition

import unslug from "unslug"

export const getSlug = str => {
  str = str.replace(/^\s+|\s+$/g, "") // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  var to = "aaaaeeeeiiiioooouuuunc------"
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes

  return str
}

export const getPostImageSrc = ({ encoded: content }) => {
  const html = content.substring(
    content.indexOf("<img alt="),
    content.indexOf("/>") + 2
  )

  const src = html.substring(
    html.lastIndexOf('src="') + 5,
    html.lastIndexOf('"')
  )

  return src
}

export const getPostCategories = postCategories => {
  const toReturn = []

  const categories = [
    "Work Culture",
    "Design",
    "Development",
    "Project Management",
    "Strategy",
    "Misc",
    "Work Culture Design",
  ]

  postCategories.forEach(postCategory => {
    categories.forEach(category => {
      if (category.toLowerCase() === unslug(postCategory).toLowerCase()) {
        toReturn.push(category)
      }
    })
  })

  return toReturn
}

export const getExcerpt = ({ encodedSnippet: content }) => {
  if (!content) {
    return null
  }

  let toReturn = ""
  const wordArray = content.split(" ")

  for (let i = 0; i < 27 && i < wordArray.length; i++) {
    toReturn = toReturn + wordArray[i] + " "
  }

  toReturn = toReturn + "..."

  return toReturn
}

export const isEmailVaild = email => {
  let regex = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
  )

  return regex.test(email)
}

import React from "react"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

//Libraries
import { Trans } from "react-i18next"

// Components
import Container from "components/container/"
import JobPosition from "components/job-position"

// Images
import JobOpeningsImage from "assets/images/careers/job-openings.svg"

const StyledJobOpenings = styled.section`
  width: 100%;
  padding: 48px 0 80px 0;
  display: flex;
  align-items: center;
  color: ${colors.midnight};

  ${breakpoint.medium`
    padding: 120px 0 200px 0;
  `}

  ${Container} {
    max-width: 928px;
  }

  .h4--detail {
    margin-bottom: 48px;
  }

  .table_section {
    margin-top: 100px;
  }

  .jobs-load_more {
    margin-top: 48px;

    ${breakpoint.medium`
    display: none;
    `}
  }
`

const JobOpenings = props => {
  const { data } = props

  return (
    <StyledJobOpenings>
      <Container>
        <div className="row text-center justify-content-md-between align-items-center">
          <div className="col-12 col-md-7">
            <h3 className="text-start font-weight--500">
              <Trans>
                We’re connected at a distance. Fully remote positions for
                maximum flexibility.
              </Trans>
            </h3>
          </div>
          <div className="col-12 col-md-5 mt-5">
            <img src={JobOpeningsImage} />
          </div>
        </div>
        <div className="table_section">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <h4 className="h4--detail">
                <Trans>Open positions</Trans>
              </h4>
              {data &&
                data.map(job => {
                  return (
                    <JobPosition
                      title={job.title}
                      duration={job.modality}
                      time={job.creationDate}
                      slug={job.slug}
                      key={job.id}
                    />
                  )
                })}
            </div>
          </div>
        </div>
      </Container>
    </StyledJobOpenings>
  )
}

export default JobOpenings
